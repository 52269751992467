<template>
  <div class="content">
    <div class="orderStatusWrap">
      <div class="orderStatus">
        <p>{{ orderStatus }}</p>
        <p>下单时间：{{ orderDate }}</p>
      </div>
    </div>
    <div class="orderContent">
      <p class="orderTitle">订单信息</p>
      <div class="orderDetailWrap">
        <img :src="orderImage" :alt="orderName">
        <div class="orderDetail">
          <p class="orderDetailName">{{ orderName }}</p>
          <p class="orderDetailType">{{ orderType }}</p>
          <div class="orderDetailPrice">
            <p>¥{{ orderPrice | MoneyFormat }}</p>
            <p>×️{{ orderQuantity }}</p>
          </div>
        </div>
      </div>
      <div class="orderInfo">
        <p>商品总计</p>
        <p>¥ {{ totalAmount | MoneyFormat }}</p>
      </div>
      <div class="orderInfo">
        <p>实际金额</p>
        <p>¥ {{ orderAmount | MoneyFormat }}</p>
      </div>
      <div class="orderInfo">
        <p>已优惠</p>
        <p>¥ {{ preferentialAmount | MoneyFormat }}</p>
      </div>
    </div>
    <div class="orderNumber">
      <div class="orderNumberDetail">
        <p>充值视频手机号：{{ mobile }}</p>
      </div>
      <div class="orderNumberDetail">
        <p>订单号：{{ orderNo }}</p>
      </div>
    </div>
    <div class="orderNote">
      <p>充值说明</p>
      <p v-for="(item,index) in orderDocs" :key="index">{{ item }}</p>
      <!--            <p>1、本商品为视频会员激活卡充值，付款成功后请注意查收短信。</p>-->
      <!--            <p>2、根据短信内的激活码，进入所冲视频APP的激活码兑换，进-->
      <!--                行激活码充值。</p>-->
      <!--            <p>3、请确认充值账号的正确性，虚拟商品已经充值不退货退款。</p>-->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import {
  getVideoOrderDetail
} from '@/services/comeOn.js'
import { formatNumber } from '@/utils/common/utils'

export default {
  name: 'VideoDetail',
  filters: {
    MoneyFormat(v) {
      if (!(v && v !== '')) {
        return '0.00'
      } else {
        return formatNumber(v, 2, '.', ',')
      }
    }
  },
  data() {
    return {
      mobile: '',
      orderNo: '', // 订单号
      orderStatus: '', // 订单状态
      orderDate: '', // 下单时间
      orderImage: '', // img
      orderName: '', // 名称
      orderType: '', // 类型
      orderPrice: '', // 金额
      orderQuantity: '', // 数量
      totalAmount: '', // 商品总计
      orderAmount: '', // 实际金额
      preferentialAmount: '', // 已优惠
      orderDocs: []
    }
  },
  created() {
    this.orderNo = this.$route.query ? this.$route.query.orderNo : this.$route.query.orderNo
  },
  mounted() {
    this.getVideoOrderDetail()
  },
  methods: {
    getVideoOrderDetail() {
      getVideoOrderDetail({
        orderNo: this.orderNo
      }).then(res => {
        if (Number(res.code) === 200) {
          this.orderStatus = res.data.statusText
          this.orderDate = res.data.createTime
          this.orderImage = res.data.goodsImage
          this.orderName = res.data.goodsName
          this.orderType = res.data.skuName
          this.orderPrice = res.data.totalAmount
          this.orderQuantity = res.data.goodsNum
          this.totalAmount = res.data.totalAmount
          this.orderAmount = res.data.payAmount
          this.preferentialAmount = res.data.preferentialAmount
          this.mobile = res.data.phone
          this.orderNo = res.data.orderNo
          this.orderDocs = res.data.orderDocs
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  text-align: left;
  font-family: 'PingFang SC Medium';
}

.content {
  position: absolute;

  .orderStatusWrap {
    width: 100%;
    height: 125px;
    background-color: #F7263C;
    padding: 45px;
    color: #ffffff;

    .orderStatus {
      p:nth-child(1) {
        font-size: 16px;
      }

      p:nth-child(2) {
        margin-top: 11px;
        font-size: 12px;
      }
    }
  }

  .orderContent {
    background-color: #ffffff;
    padding: 15px 20px;
    margin-top: 11px;

    .orderTitle {
      font-size: 14px;
      color: #141414;
      font-weight: 500;
    }

    .orderDetailWrap {
      margin-top: 20px;
      display: flex;

      img {
        width: 51px;
        height: 51px;
        margin-top: 9px;
        margin-right: 19px;
        border-radius: 50%;
      }

      .orderDetail {
        width: 80%;

        .orderDetailName {
          font-size: 14px;
          color: #141414;
        }

        .orderDetailType {
          font-size: 13px;
          color: #999999;
          margin-top: 13px;
        }

        .orderDetailPrice {
          margin-top: 12px;
          display: flex;
          font-size: 14px;

          p:nth-child(1) {
            color: #333333;
            font-weight: 500;
          }

          p:nth-child(2) {
            color: #999999;
            margin-left: auto;
          }
        }
      }
    }
  }

  .orderInfo {
    margin-top: 15px;
    display: flex;

    p {
      font-size: 12px;
      color: #999999;
    }

    p:nth-child(2) {
      margin-left: auto;
    }
  }

  .orderInfo:nth-child(1) {
    margin-top: 25px;
  }

  .orderNumber {
    width: 100%;
    height: 68px;
    padding: 15px 22px;
    margin-top: 10px;
    background-color: #fff;
    font-size: 12px;

    .orderNumberDetail {
      p {
        color: #999999;
      }
    }

    .orderNumberDetail:nth-child(2) {
      margin-top: 15px;
    }
  }

  .orderNote {
    font-family: 'PingFang SC Regular';
    padding: 21px;
    color: #000000;
    font-size: 12px;
    line-height: 15px;

    p:nth-child(1) {
      font-size: 14px;
    }

    p:nth-child(2) {
      margin-top: 12px;
    }
  }
}
</style>
